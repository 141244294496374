import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-input-decorator',
  styleUrl: 'x-input-decorator.sass',
})
export class XInputDecorator {
  @Prop({ reflect: true }) position: 'start' | 'end' = 'start';

  render() {
    return (
      <Host slot={`adornment-${this.position}`}>
        <slot></slot>
      </Host>
    );
  }
}
