x-input-decorator
  +preset(3)
  display: flex
  align-items: center
  justify-content: center
  min-width: 40px
  height: 100%
  padding: 0 11px
  box-sizing: border-box
  background-color: var(--input-disabled-bg)
  border: 0 solid var(--input-static)
  color: var(--on-input-dark-50)
  &[position="start"]
    border-top-left-radius: inherit
    border-bottom-left-radius: inherit
    border-right-width: 1px
  &[position="end"]
    border-top-right-radius: inherit
    border-bottom-right-radius: inherit
    border-left-width: 1px
